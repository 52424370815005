import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import DashboardAppPage from './pages/DashboardAppPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';



const SendNotification = React.lazy(() => import('./pages/Notifications/SendNotification'));

const Analytics = React.lazy(() => import('./pages/Analytics/Analytics'));
const CashAnalytics = React.lazy(() => import('./pages/Analytics/CashAnalytics'));
const CardAnalytics = React.lazy(() => import('./pages/Analytics/CardAnalytics'));

const HomeContent = React.lazy(() => import('./pages/HomeContent/HomeContent'));

const CategoryListPage = React.lazy(() => import('./pages/Kateqoriya/CategoryListPage'));
const CategoryCreatePage = React.lazy(() => import('./pages/Kateqoriya/CategoryCreatePage'));
const CategorySubCreatePage = React.lazy(() => import('./pages/Kateqoriya/CategorySubCreatePage'));
const CategoryUpdatePage = React.lazy(() => import('./pages/Kateqoriya/CategoryUpdatePage'));
const CategoryViewPage = React.lazy(() => import('./pages/Kateqoriya/CategoryViewPage'));

const EmployeeRoleList = React.lazy(() => import('./pages/EmployeeRole/EmployeeRoleList'));
const CreateEmployeeRole = React.lazy(() => import('./pages/EmployeeRole/CreateEmployeeRole'));
const UpdateEmployeeRole = React.lazy(() => import('./pages/EmployeeRole/UpdateEmployeeRole'));

const EmployeeList = React.lazy(() => import('./pages/Employees/EmployeeList'));
const StoreEmployeeList = React.lazy(() => import('./pages/Employees/StoreEmployeeList'));
const EmployeeCreate = React.lazy(() => import('./pages/Employees/EmployeeCreate'));
const StoreEmployeeCreate = React.lazy(() => import('./pages/Employees/StoreEmployeeCreate'));
const EmployeeUpdate = React.lazy(() => import('./pages/Employees/EmployeeUpdate'));
const StoreEmployeeUpdate = React.lazy(() => import('./pages/Employees/StoreEmployeeUpdate'));

const OfferList = React.lazy(() => import('./pages/Offers/OfferList'));
const OfferCreate = React.lazy(() => import('./pages/Offers/OfferCreate'));
const OfferUpdate = React.lazy(() => import('./pages/Offers/OfferUpdate'));

const CommentListPage = React.lazy(() => import('./pages/Comment/CommentListPage'));
const CommentViewPage = React.lazy(() => import('./pages/Comment/CommentViewPage'));

const ContactUsRequestListPage = React.lazy(() => import('./pages/Requests/ContactUs/ContactUsRequestListPage'));

const UserReportRequestListPage = React.lazy(() => import('./pages/Requests/Reports/UserReportRequestListPage'));
const RefundRequestListPage = React.lazy(() => import('./pages/Requests/Refund/RefundRequestListPage'));

const FaqListPage = React.lazy(() => import('./pages/Contents/Faq/FaqListPage'));
const FaqCreatePage = React.lazy(() => import('./pages/Contents/Faq/FaqCreatePage'));
const FaqUpdatePage = React.lazy(() => import('./pages/Contents/Faq/FaqUpdatePage'));
const FaqViewPage = React.lazy(() => import('./pages/Contents/Faq/FaqViewPage'));

const ProductCreateTerms = React.lazy(() => import('./pages/Contents/ProductCreateTerms/ProductCreateTerms'));
const DeliveryTerms = React.lazy(() => import('./pages/Contents/DeliveryTerms/DeliveryTerms'));
const DeliveryTermUpdate = React.lazy(() => import('./pages/Contents/DeliveryTerms/DeliveryTermUpdate'));
const DeliveryTermCreate = React.lazy(() => import('./pages/Contents/DeliveryTerms/DeliveryTermCreate'));
const PaymentTerms = React.lazy(() => import('./pages/Contents/PaymentTerms/PaymentTerms'));
const OrderTerms = React.lazy(() => import('./pages/Contents/OrderTerms/OrderTerms'));
const RegisterTerms = React.lazy(() => import('./pages/Contents/RegisterTerms/RegisterTerms'));

const SeoPage = React.lazy(() => import('./pages/Seo/SeoPage'));
const UpdateHomePageSeo = React.lazy(() => import('./pages/Seo/UpdateHomePageSeo'));
const UpdateNewProductsPageSeo = React.lazy(() => import('./pages/Seo/UpdateNewProductsPageSeo'));
const UpdateSalePageSeo = React.lazy(() => import('./pages/Seo/UpdateSalePageSeo'));
const UpdateMostSalePageSeo = React.lazy(() => import('./pages/Seo/UpdateMostSalePageSeo'));
const UpdateProductsPageSeo = React.lazy(() => import('./pages/Seo/UpdateProductsPageSeo'));
const UpdateStorePageSeo = React.lazy(() => import('./pages/Seo/UpdateStorePageSeo'));
const UpdateDeliveryPageSeo = React.lazy(() => import('./pages/Seo/UpdateDeliveryPageSeo'));
const UpdatePaymentPageSeo = React.lazy(() => import('./pages/Seo/UpdatePaymentPageSeo'));
const UpdateCartPageSeo = React.lazy(() => import('./pages/Seo/UpdateCartPageSeo'));
const UpdateFavoritePageSeo = React.lazy(() => import('./pages/Seo/UpdateFavoritePageSeo'));
const UpdateNotificationPageSeo = React.lazy(() => import('./pages/Seo/UpdateNotificationPageSeo'));
const UpdateCategoryPageSeo = React.lazy(() => import('./pages/Seo/UpdateCategoryPageSeo'));
const UpdateContactPageSeo = React.lazy(() => import('./pages/Seo/UpdateContactPageSeo'));
const UpdateFaqPageSeo = React.lazy(() => import('./pages/Seo/UpdateFaqPageSeo'));
const SeoScriptsPage = React.lazy(() => import('./pages/Seo/SeoScriptsPage'));

const ContactListPage = React.lazy(() => import('./pages/Contents/Contact/ContactListPage'));
const ContactUpdatePage = React.lazy(() => import('./pages/Contents/Contact/ContactUpdatePage'));

const BannerList = React.lazy(() => import('./pages/Banners/BannerList'));
const BannerCreate = React.lazy(() => import('./pages/Banners/BannerCreate'));
const BannerUpdate = React.lazy(() => import('./pages/Banners/BannerUpdate'));

const BrandListPage = React.lazy(() => import('./pages/Brand/BrandListPage'));
const BrandCreatePage = React.lazy(() => import('./pages/Brand/BrandCreatePage'));
const BrandUpdatePage = React.lazy(() => import('./pages/Brand/BrandUpdatePage'));

const OrderListPage = React.lazy(() => import('./pages/Order/OrderListPage'));

const UserListPage = React.lazy(() => import('./pages/User/UserListPage'));
const UserViewPage = React.lazy(() => import('./pages/User/UserViewPage'));

const BloggerListPage = React.lazy(() => import('./pages/Blogger/BloggerListPage'));
const BloggerRequestsListPage = React.lazy(() => import('./pages/BloggerRequests/BloggerRequestsListPage'));
const BloggerListsListPage = React.lazy(() => import('./pages/BloggerLists/BloggerListsListPage'));

const ProductListPage = React.lazy(() => import('./pages/Product/ProductListPage'));
const ProductUpdatePage = React.lazy(() => import('./pages/Product/ProductUpdatePage'));
const ProductUserDetail = React.lazy(() => import('./pages/Product/ProductUserDetail'));

const PromoList = React.lazy(() => import('./pages/Promo/PromoList'));
const PromoCreate = React.lazy(() => import('./pages/Promo/PromoCreate'));

const ChatPage = React.lazy(() => import('./pages/Chat/ChatPage'));
const ChatPageInner = React.lazy(() => import('./pages/Chat/ChatPageInner'));
const MobileDetailsPage = React.lazy(() => import('./pages/Contents/MobileDetails/MobileDetails'));

const FamilyListPage = React.lazy(() => import('./pages/Family/FamilyListPage'));
const FamilyCreatePage = React.lazy(() => import('./pages/Family/FamilyCreatePage'));

const PurposeListPage = React.lazy(() => import('./pages/Purpose/PurposeListPage'));
const PurposeCreatePage = React.lazy(() => import('./pages/Purpose/PurposeCreatePage'));

const RecipientListPage = React.lazy(() => import('./pages/Receipt/ReceiptListPage'));
const RecipientCreatePage = React.lazy(() => import('./pages/Receipt/ReceiptCreatePage'));

const PageListPage = React.lazy(() => import('./pages/Page/PageListPage'));
const PageCreatePage = React.lazy(() => import('./pages/Page/PageCreatePage'));
const PageEditPage = React.lazy(() => import('./pages/Page/PageEditPage'));

// ----------------------------------------------------------------------

export default function Router() {
  const auth = useSelector((state) => state.auth);

  const routes = useRoutes([
    {
      path: '/dashboard',
      element: !auth.isAuthenticated ? <Navigate to="/login" /> : <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'category', element: <CategoryListPage /> },
        { path: 'category/create', element: <CategoryCreatePage /> },
        { path: 'category/:id/edit/*', element: <CategoryUpdatePage /> },
        { path: 'category/:id/create/*', element: <CategorySubCreatePage /> },
        { path: 'category/:id/view/*', element: <CategoryViewPage /> },

        { path: 'admin-panel-employees', element: <EmployeeList /> },
        { path: 'store-panel-employees', element: <StoreEmployeeList /> },
        { path: 'admin-panel-employees/create', element: <EmployeeCreate /> },
        { path: 'store-panel-employees/create', element: <StoreEmployeeCreate /> },
        { path: 'admin-panel-employees/:id/update', element: <EmployeeUpdate /> },
        { path: 'store-panel-employees/:id/update', element: <StoreEmployeeUpdate /> },
        { path: 'admin-panel-employees-role', element: <EmployeeRoleList /> },
        { path: 'admin-panel-employees-role/create', element: <CreateEmployeeRole /> },
        { path: 'admin-panel-employees-role/:id/edit', element: <UpdateEmployeeRole /> },

        { path: 'offers', element: <OfferList /> },
        { path: 'offers/create', element: <OfferCreate /> },
        { path: 'offers/:id/update', element: <OfferUpdate /> },

        { path: 'comment', element: <CommentListPage /> },
        { path: 'comment/:id/view/*', element: <CommentViewPage /> },

        { path: 'request/contact-us', element: <ContactUsRequestListPage /> },
        { path: 'request/user-reports', element: <UserReportRequestListPage /> },
        { path: 'request/refund', element: <RefundRequestListPage /> },

        { path: 'faq', element: <FaqListPage /> },
        { path: 'faq/create', element: <FaqCreatePage /> },
        { path: 'faq/:id/edit/*', element: <FaqUpdatePage /> },
        { path: 'faq/:id/view/*', element: <FaqViewPage /> },

        { path: 'product-create-terms', element: <ProductCreateTerms /> },
        { path: 'delivery-terms', element: <DeliveryTerms /> },
        { path: 'delivery-term/create', element: <DeliveryTermCreate /> },
        { path: 'delivery-term/:id/edit/*', element: <DeliveryTermUpdate /> },
        { path: 'payment-terms', element: <PaymentTerms /> },
        { path: 'order-terms', element: <OrderTerms /> },
        { path: 'register-terms', element: <RegisterTerms /> },

        { path: 'search-engine-optimization', element: <SeoPage /> },
        { path: 'seo/home/edit', element: <UpdateHomePageSeo /> },
        { path: 'seo/new-products/edit', element: <UpdateNewProductsPageSeo /> },
        { path: 'seo/most-saled/edit', element: <UpdateMostSalePageSeo /> },
        { path: 'seo/sale/edit', element: <UpdateSalePageSeo /> },
        { path: 'seo/products/edit', element: <UpdateProductsPageSeo /> },
        { path: 'seo/store/edit', element: <UpdateStorePageSeo /> },
        { path: 'seo/delivery/edit', element: <UpdateDeliveryPageSeo /> },
        { path: 'seo/payment/edit', element: <UpdatePaymentPageSeo /> },
        { path: 'seo/cart/edit', element: <UpdateCartPageSeo /> },
        { path: 'seo/favorite/edit', element: <UpdateFavoritePageSeo /> },
        { path: 'seo/notification/edit', element: <UpdateNotificationPageSeo /> },
        { path: 'seo/category/edit', element: <UpdateCategoryPageSeo /> },
        { path: 'seo/contact/edit', element: <UpdateContactPageSeo /> },
        { path: 'seo/faq/edit', element: <UpdateFaqPageSeo /> },
        { path: 'seo-scripts', element: <SeoScriptsPage /> },

        { path: 'contact', element: <ContactListPage /> },
        { path: 'contact/edit', element: <ContactUpdatePage /> },

        { path: 'banners', element: <BannerList /> },
        { path: 'banner/create', element: <BannerCreate /> },
        { path: 'banner/:id/update', element: <BannerUpdate /> },

        { path: 'brand', element: <BrandListPage /> },
        { path: 'brand/create', element: <BrandCreatePage /> },
        { path: 'brand/:id/update', element: <BrandUpdatePage /> },

        { path: 'order', element: <OrderListPage /> },
        { path: 'chat', element: <ChatPage /> },
        { path: 'chat/:id', element: <ChatPageInner /> },

        { path: 'user', element: <UserListPage /> },
        { path: 'user/:id/view/*', element: <UserViewPage /> },

        { path: 'notifications', element: <SendNotification /> },

        { path: 'home-content', element: <HomeContent /> },

        { path: 'analytics', element: <Analytics /> },
        { path: 'analytics/cash', element: <CashAnalytics /> },
        { path: 'analytics/card', element: <CardAnalytics/> },

        { path: 'blogger', element: <BloggerListPage /> },
        { path: 'blogger-requests', element: <BloggerRequestsListPage /> },
        { path: 'blogger-lists', element: <BloggerListsListPage /> },

        { path: 'product', element: <ProductListPage /> },
        { path: 'product/:id/update', element: <ProductUpdatePage /> },
        { path: 'product/product-cart/:productId', element: <ProductUserDetail /> },


        { path: 'promo/', element: <PromoList /> },
        { path: 'promo/create', element: <PromoCreate /> },

        { path: 'mobile-details', element: <MobileDetailsPage /> },

        { path: 'family', element: <FamilyListPage /> },
        { path: 'family/create', element: <FamilyCreatePage /> },

        { path: 'purpose', element: <PurposeListPage /> },
        { path: 'purpose/create', element: <PurposeCreatePage /> },

        { path: 'recipient', element: <RecipientListPage /> },
        { path: 'recipient/create', element: <RecipientCreatePage /> },

        { path: 'page', element: <PageListPage /> },
        { path: 'page/create', element: <PageCreatePage /> },
        { path: 'page/:id/update', element: <PageEditPage /> },
      ],
    },
    {
      path: 'login',
      element: auth.isAuthenticated ? <Navigate to="/dashboard" /> : <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
